import React, { useState, useEffect } from "react";
import { Beat } from "../../../redux/reducers/spinner";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../redux/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import Location from "../../includes/Location";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function CreateRider() {
  const dispatch = useDispatch();

  //This is to get company details
  const [myCompany, setMyCompany] = React.useState({});
  useEffect(() => {
    const getMyCompany = async () => {
      let companyData = JSON.parse(await localStorage.getItem("myCompany"));
      companyData && setMyCompany(companyData);
      companyData &&
        setData((data) => ({
          ...data,
          user_city: companyData.company_city,
          user_state: companyData.company_state,
          user_country: companyData.company_country,
        }));
    };
    getMyCompany();
  }, []);

  // Get company id, to be used as input for creating employee
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const id = user.data.groups[teamId].company_id;

  // Modal actions
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // Used to toggle the password visibility
  function toggleFunction(e) {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }

    // toggle the eye slash icon
    e.target.classList.toggle("i-Eye1");
  }
  function toggleFunction2(e) {
    var x = document.getElementById("password2");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }

    // toggle the eye slash icon
    e.target.classList.toggle("i-Eye1");
  }

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  // Form actions
  const [submitted, setSubmitted] = useState(false);
  let [courier_phone, setCourierPhone] = useState("");
  const [data, setData] = useState({
    company_id: "",
    user_name: "",
    user_email: "",
    user_password: "",
    user_password_confirmation: "",
    user_phone: "",
    // courier_name: "",
    user_job_title: "",
    // courier_phone: "",
    user_address: "",
    user_city: "",
    user_state: "",
    user_country: "",
  });
  const {
    user_email,
    user_password,
    user_password_confirmation,
    user_name,
    user_job_title,
    user_address,
    user_city,
    user_state,
    user_country,
  } = data;
  const creating = useSelector((state) => state.users.creating);
  courier_phone = courier_phone?.substring(1);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  // Create courier and also set the action of courier
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (user_email&&
      user_name &&
      // courier_phone &&
      user_address &&
      user_city &&
      user_state &&
      user_country
    ) {
      // dispatch action to create courier

      dispatch(
        userActions.createRider(
          {
            ...data,
            // courier_phone,
            company_id: id,
            user_name: data.user_name,
            user_phone: courier_phone,
          },
          id
        )
      );

      handleClose();
    }
  };

  return (
    <div>
      <MDBContainer>
        <MDBBtn color="primary" onClick={() => setOpen(true)}>
          Create Rider
        </MDBBtn>
        <MDBModal isOpen={open} toggle={handleClose}>
          <form name="form" onSubmit={handleSubmit}>
            <MDBModalHeader toggle={handleClose}>
              Add a new rider to your company
            </MDBModalHeader>
            <MDBModalBody>
              <div className="text-left row pt-4">
                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && !user_email ? " has-error" : "")
                  }
                >
                  <label htmlFor="user_email">Rider name*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="user_email"
                    onChange={handleChange}
                  />
                  {submitted && !user_email && (
                    <div className="help-block">Rider name is required</div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && !user_email ? " has-error" : "")
                  }
                >
                  <label htmlFor="user_email">Rider email*</label>
                  <input
                    type="email"
                    className="form-control"
                    name="user_email"
                    onChange={handleChange}
                    required
                  />
                  {submitted && !user_email && (
                    <div className="help-block">Rider email is required</div>
                  )}
                </div>
                {/* <div className={'form-group col-md-6 mb-4' + (submitted && user_phone ? ' has-error' : '')}>
                                    <label htmlFor="user_phone">Rider phone*</label>
                                    <input type="number" min="0" className="form-control" name="user_phone" onChange={handleChange} />
                                    {submitted && !user_phone &&
                                        <div className="help-block">Rider phone is required</div>
                                    }
                                </div> */}
                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && user_password ? " has-error" : "")
                  }
                >
                  <label htmlFor="user_password">Rider password*</label>
                  <br />
                  <small>Must have a minimum of 6 characters</small>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    name="user_password"
                    onChange={handleChange}
                  />
                  {submitted && !user_password && (
                    <div className="help-block">Rider password is required</div>
                  )}
                  <i
                    className="i-Eye-Visible toggleIcon"
                    onClick={toggleFunction}
                  ></i>
                </div>
                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && user_password_confirmation
                      ? " has-error"
                      : "")
                  }
                >
                  <label htmlFor="user_password_confirmation">
                    Rider password confirmation*
                  </label>
                  <br />
                  <small>Must have a minimum of 6 characters</small>
                  <input
                    type="password"
                    id="password2"
                    className="form-control"
                    name="user_password_confirmation"
                    onChange={handleChange}
                  />
                  {submitted && !user_password_confirmation && (
                    <div className="help-block">
                      Rider password confirmation is required
                    </div>
                  )}
                  <i
                    className="i-Eye-Visible toggleIcon"
                    onClick={toggleFunction2}
                  ></i>
                </div>

                {/* <div className={'form-group col-md-6 mb-4' + (submitted && courier_company_name ? ' has-error' : '')}>
                                    <label htmlFor="courier_company_name">Rider company name*</label>
                                    <input type="text" className="form-control" name="courier_company_name" onChange={handleChange} />
                                    {submitted && !courier_company_name &&
                                        <div className="help-block">Rider company name is required</div>
                                    }
                                </div> */}
                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && user_job_title ? " has-error" : "")
                  }
                >
                  <label htmlFor="user_job_title">Rider job title</label>
                  <input
                    type="text"
                    name="user_job_title"
                    onChange={handleChange}
                    className={
                      "form-control" +
                      (submitted && !user_job_title ? " is-invalid" : "")
                    }
                  />
                  {submitted && !user_job_title && (
                    <div className="help-block">
                      Rider job title is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && courier_phone ? " has-error" : "")
                  }
                >
                  <label htmlFor="courier_phone">Rider phone*</label>
                  <PhoneInput
                    defaultCountry="NG"
                    className="form-control"
                    value={courier_phone}
                    onChange={setCourierPhone}
                  />
                  {/* {submitted && !courier_phone && (
                    <div className="help-block">Rider phone is required</div>
                  )} */}
                </div>
                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && user_address ? " has-error" : "")
                  }
                >
                  <label htmlFor="user_address">Rider address*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="user_address"
                    onChange={handleChange}
                  />
                  {submitted && !user_address && (
                    <div className="help-block">Rider address is required.</div>
                  )}
                </div>

                {/* Here, we utilize the location component to get the location data */}
                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  countryList={(value) => {
                    setLocationList({ ...locationList, countryList: value });
                  }}
                  stateList={(value) => {
                    setLocationList({ ...locationList, stateList: value });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                  country_name={myCompany.company_country}
                  state_name={myCompany.company_state}
                  city_name={myCompany.company_city}
                />

                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && user_country ? " has-error" : "")
                  }
                >
                  <label htmlFor="user_country">Rider country*</label>
                  <select
                    name="user_country"
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {myCompany.company_country && (
                      <option value={myCompany.company_country}>
                        {myCompany.company_country}
                      </option>
                    )}
                    {locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !user_country && (
                    <div className="help-block">Rider country is required</div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && user_state ? " has-error" : "")
                  }
                >
                  <label htmlFor="user_state">Rider state</label>
                  <select
                    name="user_state"
                    className="form-control"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        state_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {myCompany.company_state && (
                      <option value={myCompany.company_state}>
                        {myCompany.company_state}
                      </option>
                    )}
                    {locationList.stateList &&
                      locationList.stateList.length > 0 &&
                      locationList.stateList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !user_state && (
                    <div className="help-block">Rider state is required.</div>
                  )}
                </div>
                <div
                  className={
                    "form-group col-md-6 mb-4" +
                    (submitted && user_city ? " has-error" : "")
                  }
                >
                  <label htmlFor="user_city">Rider city* </label>
                  <select
                    className="form-control"
                    name="user_city"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        city_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    {myCompany.company_city && (
                      <option value={myCompany.company_city}>
                        {myCompany.company_city}
                      </option>
                    )}
                    {locationList.cityList &&
                      locationList.cityList.length > 0 &&
                      locationList.cityList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !user_city && (
                    <div className="help-block">Rider city is required</div>
                  )}
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setOpen(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="primary" type="submit">
                {creating === true ? (
                  <Beat loading={creating} />
                ) : (
                  "Create Rider"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    </div>
  );
}

export default CreateRider;
