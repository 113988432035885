import React, { useState, useEffect } from "react";
import MainApp from "./../layouts/MainApp";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, userActions } from "../../redux/actions";
import {  RIDER, ORDER, CUSTOMER } from "../images/images";
import { history } from "../../redux/helpers";
import moment from "moment";
import PrimaryLoader from "../includes/Loaders/PrimaryLoader";

export default function DashboardN() {
  let user = JSON.parse(localStorage.getItem("user"));
  let teamId = JSON.parse(localStorage.getItem("teamId"));
  const company_id = user?.data?.groups[teamId]?.company_id;

  if (!company_id || company_id === "undefined" || "") {
    history("/auth/userClearance");
  }

  let last4months = new Date();
  last4months.setDate(last4months.getDate() - 100);


  const [end_date] = useState(new Date());
  const dispatch = useDispatch();

  //give access to only a few people

  // let user_rank = (user) => {
  //   if (user?.data?.team_roles[teamId]?.roles?.includes("company")) {
  //     return "company_owner";
  //   }
  //   if (user?.data?.team_roles[teamId]?.roles?.includes("accountant")) {
  //     return "company_accountant";
  //   }
  // };
  //fetch the company data, retrieve it from the reducer and store in local storage
  useEffect(() => {
    // dispatch(adminActions.getMyCompany());
    dispatch(adminActions.getACompany(company_id));
    // eslint-disable-next-line
  }, [dispatch, company_id]);
  const myCompany = useSelector((state) => state.admin.singleCompany);
  const loading = useSelector((state) => state.admin.getCompanyLoader);
  localStorage.setItem("myCompany", JSON.stringify(myCompany));

  useEffect(() => {
    
    dispatch(adminActions.getGeneralStats(company_id, end_date));
  }, [dispatch, company_id,  end_date]);

  useEffect(() => {
    dispatch(adminActions.getCustomers(company_id));
    dispatch(userActions.getActiveRider());
    dispatch(adminActions.getAllOutlet(company_id));
  }, [dispatch, company_id]);

  var today = new Date();

 // const companyStats = useSelector((state) => state.admin.companyStats[0]);
  const generalStats = useSelector((state) => state.admin.generalStats);
  const allCustomers = useSelector((state) => state.admin.allCustomers);
  const activeRiders = useSelector((state) => state.users.rider_active);
  const allOutlet = useSelector((state) => state.admin.allOutlet);
  let customerData = allCustomers && allCustomers.data;

  return (
    <MainApp>
      {loading ? (
        <PrimaryLoader />
      ) : (
        <div className='main-content-padding'>
          <div className='main-content'>
            <div className='breadcrumb'>
              <h1 className='mr-2 text-capitalize'>
                Welcome {user.data.name},
              </h1>
            </div>
            <div className='separator-breadcrumb border-top' />

            <div className='row'>
              <div className='col-md-7'>
                {generalStats && (
                  <div
                    className={
                      generalStats.stats &&
                      generalStats.stats.unassigned_orders > 0
                        ? "card mb-4 mt-4 order-gradient"
                        : "card mb-4 mt-4"
                    }>
                    <div className='d-flex p-2 px-3 bg-white justify-content-between align-items-center'>
                      <div className='d-flex align-items-center'>
                        <div
                          className='card p-1'
                          style={{
                            background: "#D14522",
                            width: "42px",
                            height: "42px",
                          }}>
                          {generalStats && (
                            <h2 className='t-font-boldest m-0 text-center text-white'>
                              {generalStats.stats &&
                                generalStats.stats.unassigned_orders}
                            </h2>
                          )}
                        </div>
                        <h5 className='heading ml-2'>New Orders</h5>
                      </div>
                      <div>
                        <a href='/orders'>
                          Manage Orders{" "}
                          <i className='i-Arrow-Right-in-Circle ml-1'></i>
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                <div className='row mb-5'>
                  <div className='col-md-4 col-lg-4'>
                    <div className='mb-4 o-hidden'>
                      <div className='ul-card__widget-chart'>
                        <div className='d-flex card-icon-bg'>
                          <img
                            src={ORDER}
                            className='dash-icon'
                            alt='order-icon'
                          />
                          <div className='ml-2'>
                            {allOutlet && (
                              <div className='ul-widget__chart-number'>
                                <h2 className='t-font-boldest'>
                                  {allOutlet.data && allOutlet.data.total
                                    ? allOutlet.data && allOutlet.data.total
                                    : 0}
                                </h2>
                              </div>
                            )}
                            <h5 className='heading'>Total Outlets</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 col-lg-4'>
                    <div className='mb-4 o-hidden'>
                      <div className='ul-card__widget-chart'>
                        <div className='d-flex card-icon-bg'>
                          <img
                            src={RIDER}
                            className='dash-icon'
                            alt='rider-icon'
                          />
                          <div className='ml-2'>
                            {activeRiders && (
                              <div className='ul-widget__chart-number'>
                                <h2 className='t-font-boldest'>
                                  {activeRiders.length}
                                </h2>
                              </div>
                            )}
                            <h5 className='heading'>Active Drivers</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 col-lg-4'>
                    <div className='mb-4 o-hidden'>
                      <div className='ul-card__widget-chart'>
                        <div className='d-flex card-icon-bg'>
                          <img
                            src={CUSTOMER}
                            className='dash-icon'
                            alt='customer-icon'
                          />
                          <div className='ml-2'>
                            {customerData && (
                              <div className='ul-widget__chart-number'>
                                <h2 className='t-font-boldest'>
                                  {customerData?.total}
                                </h2>
                              </div>
                            )}
                            <h5 className='heading'>Total Customers</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className='mb-4 mt-4 d-flex justify-content-between'>
                  <div>
                    <h3>Your Today's report</h3>
                    <p>(i.e: the last 24 hours)</p>
                  </div>
                  <p>{moment(today).format("ll")}</p>
                </div>

                <div className='row mb-4'>
                  <div className='col-md-3 col-lg-3'>
                    <div className='mb-4 o-hidden'>
                      <div className='ul-card__widget-chart'>
                        <div className=''>
                          {generalStats && (
                            <div className='ul-widget__chart-number'>
                              <h2 className='t-font-boldest'>
                                {generalStats.stats &&
                                  generalStats.stats.total_orders}
                              </h2>
                            </div>
                          )}
                          <h5 className='heading'>Total Orders</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-lg-3'>
                    <div className='mb-4 o-hidden'>
                      <div className='ul-card__widget-chart'>
                        <div className=''>
                          {generalStats && (
                            <div className='ul-widget__chart-number'>
                              <h2 className='t-font-boldest'>
                                {generalStats.stats &&
                                  generalStats.stats.pending_orders}
                              </h2>
                            </div>
                          )}
                          <h5 className='heading'>Pending Orders</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-lg-3'>
                    <div className='mb-4 o-hidden'>
                      <div className='ul-card__widget-chart'>
                        <div className=''>
                          {generalStats && (
                            <div className='ul-widget__chart-number'>
                              <h2 className='t-font-boldest'>
                                {generalStats.stats &&
                                  generalStats.stats.canceled_orders}
                              </h2>
                            </div>
                          )}
                          <h5 className='heading'>Cancelled Orders</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3 col-lg-3'>
                    <div className='mb-4 o-hidden'>
                      <div className='ul-card__widget-chart'>
                        <div className=''>
                          {generalStats && (
                            <div className='ul-widget__chart-number'>
                              <h2 className='t-font-boldest'>
                                {generalStats.stats &&
                                  generalStats.stats.completed_orders}
                              </h2>
                            </div>
                          )}
                          <h5 className='heading'>Completed Orders</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-lg-3'>
                    <div className='mb-4 o-hidden'>
                      <div className='ul-card__widget-chart'>
                        <div className='ml-2'>
                          {generalStats && (
                            <div className='ul-widget__chart-number'>
                              <h2 className='t-font-boldest'>
                                {generalStats.stats &&
                                  generalStats.stats.accepted_orders}
                              </h2>
                            </div>
                          )}
                          <h5 className='heading'>Accepted Orders</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-6'>
                    <div className='mb-4 o-hidden'>
                      <div className='ul-card__widget-chart'>
                        <div className='ml-2'>
                          {generalStats && (
                            <div className='ul-widget__chart-number'>
                              <h2 className='t-font-boldest'>
                                {generalStats.stats &&
                                  generalStats.stats.average_delivery_time}
                              </h2>
                            </div>
                          )}
                          <h5 className='heading'>Average Delivery Time</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* {user_rank(user) === "company_owner" && (
                <div className='col-md-4 offset-md-1'>
                  <div className='mb-2 mt-4 d-flex justify-content-between'>
                    <h3>Revenue</h3>
                
                    <p>{moment(today).format("ll")}</p>
                  </div>

                  <div
                    className='card mb-4 bg-cover'
                    style={{ backgroundImage: `url(${REVENUEBG})` }}>
                    <div className='card-body'>
                      <div className='text-center my-4'>
                        <h5 className='text-white'>Total Revenue (NGN)</h5>
                        {companyStats && (
                          <h2 className='text-white t-font-boldest'>
                            {companyStats.stats &&
                              companyStats.stats.total_cost_price}
                          </h2>
                        )}
                      </div>
                      <div className='row mb-2 pt-4'>
                        <div className='col-md-12 col-lg-6 col-12'>
                          <div className='card p-2'>
                            <div className='d-flex card-icon-bg'>
                              <i className='i-File-Clipboard-File--Text'></i>
                              <div className='ml-2'>
                                {companyStats && (
                                  <h2 className='t-font-boldest'>
                                    {companyStats.stats &&
                                      companyStats.stats.total_cost_price}
                                  </h2>
                                )}
                                <h6 className='heading'>
                                  Total <br />
                                  Cost
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-12 col-lg-6 col-12'>
                          <div className='card p-2'>
                            <div className='d-flex card-icon-bg'>
                              <i className='i-File-Clipboard-File--Text'></i>
                              <div className='ml-2'>
                                {companyStats && (
                                  <h2 className='t-font-boldest'>
                                    {companyStats.stats &&
                                      companyStats.stats.total_remittance}
                                  </h2>
                                )}
                                <h6 className='heading'>
                                  Total <br />
                                  Remittance
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
              {/* {user_rank(user) === "company_accountant" && (
                <div className='col-md-4 offset-md-1'>
                  <div className='mb-2 mt-4 d-flex justify-content-between'>
                    <h3>Revenue</h3>
                  
                    <p>{moment(today).format("ll")}</p>
                  </div>

                  <div
                    className='card mb-4 bg-cover'
                    style={{ backgroundImage: `url(${REVENUEBG})` }}>
                    <div className='card-body'>
                      <div className='text-center my-4'>
                        <h5 className='text-white'>Total Revenue (NGN)</h5>
                        {companyStats && (
                          <h2 className='text-white t-font-boldest'>
                            {companyStats.stats &&
                              companyStats.stats.total_cost_price}
                          </h2>
                        )}
                      </div>
                      <div className='row mb-2 pt-4'>
                        <div className='col-md-12 col-lg-6 col-12'>
                          <div className='card p-2'>
                            <div className='d-flex card-icon-bg'>
                              <i className='i-File-Clipboard-File--Text'></i>
                              <div className='ml-2'>
                                {companyStats && (
                                  <h2 className='t-font-boldest'>
                                    {companyStats.stats &&
                                      companyStats.stats.total_cost_price}
                                  </h2>
                                )}
                                <h6 className='heading'>
                                  Total <br />
                                  Cost
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-12 col-lg-6 col-12'>
                          <div className='card p-2'>
                            <div className='d-flex card-icon-bg'>
                              <i className='i-File-Clipboard-File--Text'></i>
                              <div className='ml-2'>
                                {companyStats && (
                                  <h2 className='t-font-boldest'>
                                    {companyStats.stats &&
                                      companyStats.stats.total_remittance}
                                  </h2>
                                )}
                                <h6 className='heading'>
                                  Total <br />
                                  Remittance
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

              {/* end of row */}
            </div>
            {/* end of main-content */}
          </div>
        </div>
      )}
    </MainApp>
  );
}
