let init = {
  items: [],
  companies: [],
  allOrders: [],
  allOrdersByDate: [],
  allOrdersByDatePagination: [],
  searchOrders:[],
  unassigned: [],
  unassigned_paid: [],
  unassigned_unpaid: [],
  assigned: [],
  acceptedOrder: [],
  rejectedOrder: [],
  completed: [],
  completedByDate: [],
  cancelled: [],
  closed: [],
  delivered: [],
  completedOrder: [],
  canceledOrder: [],
  closedOrders: [],
  singleOrder: {},
  singleCourierOrder: [],
  allEmployees: [],
  singleEmployee: {},
  singleRider: [],
  allRoles: [],
  allAbility: [],
  companyStats: [],
  courierStats: [],
  employeeStats: [],
  generalStats: {},
  creating: false,
  recording: false,
  loading: false,
  assigning: false,
  accepting: false,
  closing: false,
  rejecting: false,
  updating: false,
  deleting: false,
  adding: false,
  allCustomers: [],
  myCourier: [],
  myCompleted: [],
  myPending: [],
  myAccepted: [],
  myCancelled: [],
  myRejected: [],
  pendingRemittance: [],
  completedRemittance: [],
  searched: [],
  searching: false,
  countries: {},
  allStates: [],
  allOutlet: [],
  searchedOutlet: [],
  searchedCompany: [],
  searchedEmployee: [],
  city: [],
  thirdPartyToken: [],
  myCompany: {},
  singleCompany: {},
  remitted: {},
  remittedOrders: [],
  remittedNoPageOrders: [],
  filteredOrders: [],
  locationList: {},
  loadingCompStats: false,
  loadingGeneralStats: false,
  getEmployeesloader: false,
  getOutletLoader: false,
  getOrdersLoader: false,
  getRidersLoader: false,
  getCustomerLoader: false,
  getCourierLoader: false,
  getPaginationByDateLoader: false,
  getCompanyLoader: false,
  getRemittanceLoader: false,
  getOrdersReportLoader: false,
  getDeliveryLoader: false,
  getSettingsLoader: false,
  getUnassignedLoader: false,
  getCompletedLoader: false,
  getAssignedLoader: false,
  getClosedLoader: false,
  getOrderByDateLoader: false,
  getFilteredLoader: false,
};

export function admin(state = init, action) {
  switch (action.type) {
    case "GETALL_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GETALL_SUCCESS":
      return {
        ...state,
        items: action.users,
      };

    case "GETALL_FAILURE":
      return {
        error: action.error,
      };

    case "GETALLORDERSBYDATE_REQUEST":
      return {
        ...state,
        getOrderByDateLoader: action.payload,
      };
    case "GETALLORDERSBYDATE_SUCCESS":
      return {
        ...state,

        allOrdersByDate: action.allOrdersByDate,
      };

    case "GETALLORDERSBYDATE_FAILURE":
      return {
        ...state,

        error: action.error,
      };
    case "GETALLORDERSBYDATENOPAGINATION_REQUEST":
      return {
        ...state,
        getPaginationByDateLoader: action.payload,
      };
    case "GETALLORDERSBYDATENOPAGINATION_SUCCESS":
      return {
        ...state,

        allOrdersByDatePagination: action.allOrdersByDatePagination,
      };
    case "GETALLORDERSBYDATENOPAGINATION_FAILURE":
      return {
        ...state,

        error: action.error,
      };

    case "SEARCHORDERS_REQUEST":
      return {
        ...state,
        searching:true,
      };
    case "SEARCHORDERS_SUCCESS":
      return {
        ...state,
        searching: false,
        searchOrders: action.searchOrders,
      };
    case "SEARCHORDERS_FAILURE":
      return {
        ...state,
        searching: false,
        error: action.error,
      };

    case "GET_MY_COMPLETED_REMITTANCE_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GET_MY_COMPLETED_REMITTANCE_SUCCESS":
      return {
        ...state,
        completedRemittance: action.completedRemittance,
      };

    case "GET_MY_COMPLETED_REMITTANCE_FAILURE":
      return {
        error: action.error,
      };

    case "GET_MY_CANCELLED_COURIER_ORDERS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_MY_CANCELLED_COURIER_ORDERS_SUCCESS":
      return {
        ...state,
        myCancelled: action.myCancelled,
      };

    case "GET_MY_CANCELLED_COURIER_ORDERS_FAILURE":
      return {
        error: action.error,
      };

    case "GET_MY_REJECTED_COURIER_ORDERS_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GET_MY_REJECTED_COURIER_ORDERS_SUCCESS":
      return {
        ...state,
        myRejected: action.myRejected,
      };
    case "GET_MY_REJECTED_COURIER_ORDERS_FAILURE":
      return {
        error: action.error,
      };

    case "GET_MY_PENDING_COURIER_ORDERS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_MY_PENDING_COURIER_ORDERS_SUCCESS":
      return {
        ...state,
        myPending: action.myPending,
      };
    case "GET_MY_PENDING_COURIER_ORDERS_FAILURE":
      return {
        error: action.error,
      };

    case "GET_MY_ACCEPTED_COURIER_ORDERS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_MY_ACCEPTED_COURIER_ORDERS_SUCCESS":
      return {
        ...state,
        myAccepted: action.myAccepted,
      };
    case "GET_MY_ACCEPTED_COURIER_ORDERS_FAILURE":
      return {
        error: action.error,
      };

    case "GET_MY_PENDING_REMITTANCE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_MY_PENDING_REMITTANCE_SUCCESS":
      return {
        ...state,
        pendingRemittance: action.pendingRemittance,
      };
    case "GET_MY_PENDING_REMITTANCE_FAILURE":
      return {
        error: action.error,
      };
    case "GET_MY_COMPLETED_COURIER_ORDERS_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GET_MY_COMPLETED_COURIER_ORDERS_SUCCESS":
      return {
        ...state,
        myCompleted: action.myCompleted,
      };
    case "GET_MY_COMPLETED_COURIER_ORDERS_FAILURE":
      return {
        error: action.error,
      };
    case "GETALLORDERS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GETALLORDERS_SUCCESS":
      return {
        ...state,
        loading: false,
        allOrders: action.allOrders,
      };

    case "GETALLORDERS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "GETUNASSIGNEDORDERS_REQUEST":
      return {
        ...state,
        getUnassignedLoader: action.payload,
      };
    case "GETUNASSIGNEDORDERS_SUCCESS":
      return {
        ...state,
        unassigned: action.unassigned,
      };

    case "GETUNASSIGNEDORDERS_FAILURE":
      return {
        ...state,
        error: action.error,
      };
    case "GETUNASSIGNEDORDERSLOADMORE_SUCCESS":
      return {
        ...state,
        unassigned: {
          ...state.unassigned,
          data: [...state.unassigned.data, ...action.unassigned],
        },
      };

    case "GETUNASSIGNED_UNPAID_ORDERS_REQUEST":
      return {
        ...state,
        getUnassignedLoader: action.payload,
      };
    case "GETUNASSIGNED_UNPAID_ORDERS_SUCCESS":
      return {
        ...state,
        loading: false,
        unassigned_unpaid: action.unassigned,
      };
    case "GETUNASSIGNED_UNPAID_ORDERS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "GETUNASSIGNED_UNPAID_LOADMORE_SUCCESS":
      return {
        ...state,
        unassigned_unpaid: {
          ...state.unassigned_unpaid,
          data: [...state.unassigned_unpaid.data, ...action.unassigned],
        },
      };

    case "GETUNASSIGNED_PAID_ORDERS_REQUEST":
      return {
        ...state,
        getUnassignedLoader: action.payload,
      };
    case "GETUNASSIGNED_PAID_ORDERS_SUCCESS":
      return {
        ...state,
        unassigned_paid: action.unassigned,
      };
    case "GETUNASSIGNED_PAID_ORDERS_FAILURE":
      return {
        ...state,
        error: action.error,
      };
    case "GETUNASSIGNED_PAID_LOADMORE_SUCCESS":
      return {
        ...state,
        unassigned_paid: {
          ...state.unassigned_paid,
          data: [...state.unassigned_paid.data, ...action.unassigned],
        },
      };

    case "GETASSIGNEDORDERS_REQUEST":
      return {
        ...state,
        getAssignedLoader: action.payload,
      };

    case "GETASSIGNEDORDERS_SUCCESS":
      return {
        ...state,
        assigned: action.assigned,
      };

    case "GETASSIGNEDORDERS_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETASSIGNEDORDERSLOADMORE_SUCCESS":
      return {
        ...state,
        assigned: {
          ...state.assigned,
          data: [...state.assigned.data, ...action.assigned],
        },
      };
    case "ASSIGNORDER_REQUEST":
      return {
        ...state,
        assigning: true,
      };
    case "ASSIGNORDER_SUCCESS":
      return {
        ...state,
        assigning: false,
      };

    case "ASSIGNORDER_FAILURE":
      return {
        ...state,
        assigning: false,
        error: action.error,
      };

    case "ACCEPTEDORDER_REQUEST":
      return {
        ...state,
        accepting: true,
      };
    case "ACCEPTEDORDER_SUCCESS":
      return {
        ...state,
        accepting: false,
        acceptedOrder: action.acceptedOrder,
      };
    case "ACCEPTEDORDER_ERROR":
      return {
        ...state,
        accepting: false,
        error: action.error,
      };

    case "REJECTEDORDER_REQUEST":
      return {
        ...state,
        rejecting: true,
      };
    case "REJECTEDORDER_SUCCESS":
      return {
        ...state,
        rejecting: false,
      };
    case "REJECTEDORDER_ERROR":
      return {
        ...state,
        rejecting: false,
        error: action.error,
      };
    case "CANCELEDORDER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CANCELEDORDER_SUCCESS":
      return {
        ...state,
        canceledOrder: action.canceledOrder,
      };
    case "CANCELEDORDER_FAILURE":
      return {
        error: action.error,
      };
    case "COMPLETEDORDER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "COMPLETEDORDER_SUCCESS":
      return {
        ...state,
        completedOrder: action.completedOrder,
      };
    case "COMPLETEDORDER_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "UNASSIGNORDER_REQUEST":
      return {
        ...state,
        assigning: true,
      };
    case "UNASSIGNORDER_SUCCESS":
      return {
        ...state,
        assigning: false,
      };

    case "UNASSIGNORDER_FAILURE":
      return {
        ...state,
        assigning: false,
        error: action.error,
      };

    case "GETCOMPLETEDORDERS_REQUEST":
      return {
        ...state,
        getCompletedLoader: action.payload,
      };
    case "GETCOMPLETEDORDERS_SUCCESS":
      return {
        ...state,
        completed: action.completed,
      };

    case "GETCOMPLETEDORDERS_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETCOMPLETEDORDERSLOADMORE_SUCCESS":
      return {
        ...state,
        completed: {
          ...state.completed,
          data: [...state.completed.data, ...action.completed],
        },
      };

    case "GETCLOSEDDORDERS_REQUEST":
      return {
        ...state,
        getClosedLoader: action.payload,
      };
    case "GETCLOSEDORDERS_SUCCESS":
      return {
        ...state,
        closedOrders: action.closedOrders,
      };

    case "GETCLOSEDORDERS_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETCLOSEDORDERSLOADMORE_SUCCESS":
      return {
        ...state,
        closedOrders: {
          ...state.closedOrders,
          data: [...state.closedOrders.data, ...action.closedOrders],
        },
      };

    case "CLOSEORDER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CLOSEORDER_SUCCESS":
      return {
        ...state,
        loading: false,
      };

    case "CLOSEORDER_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "MARK_AS_DELIVERED_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "MARK_AS_DELIVERED_SUCCESS":
      return {
        ...state,
        loading: false,
      };
    case "MARK_AS_DELIVERED_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "GETSINGLEORDER_REQUEST":
      return {
        ...state,
        singleOrder: action.order,
      };
    case "GETSINGLECOURIERORDER_REQUEST":
      return {
        ...state,
        singleCourierOrder: action.courierOrder,
      };

    case "GETANORDER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GETANORDER_SUCCESS":
      return {
        ...state,
        loading: false,
        singleOrder: action.order,
      };

    case "GETANORDER_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "GETACOURIERORDER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GETACOURIERORDER_SUCCESS":
      return {
        ...state,
        loading: false,
        singleCourierOrder: action.courierOrder,
      };

    case "GETACOURIERORDER_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "REMITORDER_REQUEST":
      return {
        ...state,
        creating: true,
      };
    case "REMITORDER_SUCCESS":
      return {
        ...state,
        creating: false,
        remitted: action.remitted,
      };
    case "REMITORDER_FAILURE":
      return {
        ...state,
        creating: false,
        error: action.error,
      };
    case "VERIFYREMIT_REQUEST":
      return {
        ...state,
        creating: true,
      };
    case "VERIFYREMIT_FAILURE":
      return {
        ...state,
        creating: false,
        error: action.error,
      };

    case "VERIFYREMIT_SUCCESS": {
      let stn = state.remittedOrders;
      const index = stn.data.data.findIndex(
        (obj) => obj?.id === action.verified?.courier_order_id
      ); //finding index of the item
      const newArray = [...stn.data.data]; //making a new arra
      newArray[index].remittance_verified = true; //changing value in the new array
      return {
        ...state,
        creating: false,
        remittedOrders: stn,
      };
    }

    case "GETREMITTEDORDERS_REQUEST":
      return {
        ...state,
        getRemittanceLoader: action.payload,
      };
    case "GETREMITTEDORDERS_SUCCESS":
      return {
        ...state,
        remittedOrders: action.remittedOrders,
      };

    case "GETREMITTEDORDERS_FAILURE":
      return {
        ...state,

        error: action.error,
      };

    case "GETREMITTEDORDERSNOPAGE_REQUEST":
      return {
        ...state,
        loading: action.payload,
      };
    case "GETREMITTEDORDERSNOPAGE_SUCCESS":
      return {
        ...state,
        remittedNoPageOrders: action.remittedNoPageOrders,
      };

    case "GETREMITTEDORDERSNOPAGE_FAILURE":
      return {
        ...state,

        error: action.error,
      };
    case "FILTERDELIVERY_REQUEST":
      return {
        ...state,
        getFilteredLoader: action.payload,
      };
    case "FILTERDELIVERY_SUCCESS":
      return {
        ...state,
        filteredOrders: action.filteredOrders,
        // getFilteredLoader: false,
      };

    case "FILTERDELIVERY_FAILURE":
      return {
        ...state,
        error: action.error,
        // getFilteredLoader: false,
      };

    case "GETALLCOMPANIES_REQUEST":
      return {
        ...state,
        getCompanyLoader: action.payload,
      };

    case "GETALLCOMPANIES_SUCCESS":
      return {
        ...state,
        companies: action.companies,
      };

    case "GETALLCOMPANIES_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "APPROVECOMPANY_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "APPROVECOMPANY_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETEMPLOYEES_REQUEST":
      return {
        ...state,
        getEmployeesloader: action.payload,
      };

    case "GETEMPLOYEES_SUCCESS":
      return {
        ...state,
        allEmployees: action.allEmployees,
      };

    case "GETEMPLOYEES_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "CREATEEMPLOYEES_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "CREATEEMPLOYEES_SUCCESS":
      return {
        ...state,
        loading: false,
      };

    case "CREATEEMPLOYEES_FAILURE":
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case "EDITEMPLOYEE_REQUEST":
      return {
        ...state,
        updating: true,
      };

    case "EDITEMPLOYEE_SUCCESS":
      return {
        ...state,
        updating: false,
      };

    case "EDITEMPLOYEE_FAILURE":
      return {
        ...state,
        error: action.error,
        updating: false,
      };

    case "DELETEEMPLOYEE_REQUEST":
      return {
        ...state,
        deleting: true,
      };

    case "DELETEEMPLOYEE_SUCCESS":
      return {
        ...state,
        deleting: false,
      };

    case "DELETEEMPLOYEE_FAILURE":
      return {
        ...state,
        error: action.error,
        deleting: false,
      };

    case "APPROVEEMPLOYEE_REQUEST":
      return {
        ...state,
        updating: true,
      };

    case "APPROVEEMPLOYEE_SUCCESS":
      return {
        ...state,
        updating: false,
      };

    case "APPROVEEMPLOYEE_FAILURE":
      return {
        ...state,
        error: action.error,
        updating: false,
      };

    case "SINGLEEMPLOYEE_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "SINGLEEMPLOYEE_SUCCESS":
      return {
        ...state,
        singleEmployee: action.singleEmployee,
      };

    case "SINGLEEMPLOYEE_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "SET_EMPLOYEE_ACTIVE_REQUEST":
      return {
        ...state,
      };

    case "SET_EMPLOYEE_ACTIVE_SUCCESS":
      return {
        ...state,
      };

    case "SET_EMPLOYEE_ACTIVE_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "SINGLERIDER_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "SINGLERIDER_SUCCESS":
      return {
        ...state,
        singleRider: action.singleRider,
      };

    case "SINGLERIDER_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "SET_RIDER_ACTIVE_REQUEST":
      return {
        ...state,
      };

    case "SET_RIDER_ACTIVE_SUCCESS":
      return {
        ...state,
      };

    case "SET_RIDER_ACTIVE_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETCUSTOMERS_REQUEST":
      return {
        ...state,
        getCustomerLoader: action.payload,
      };

    case "GETCUSTOMERS_SUCCESS":
      return {
        ...state,
        allCustomers: action.allCustomers,
      };

    case "GETCUSTOMERS_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "CREATECUSTOMERS_REQUEST":
      return {
        ...state,
        creating: true,
      };

    case "CREATECUSTOMERS_SUCCESS":
      return {
        ...state,
        creating: false,
      };

    case "CREATECUSTOMERS_FAILURE":
      return {
        ...state,
        error: action.error,
        creating: false,
      };

    case "SEARCH_ALL_CUSTOMERS_REQUEST":
      return {
        ...state,
        searching: true,
      };

    case "SEARCH_ALL_CUSTOMERS_SUCCESS":
      let appendedData = {
        ...state.allCustomers,
        data: {
          ...state.allCustomers?.data,
          data: [...action.searched?.data],
        },
      };
      return {
        ...state,
        allCustomers: appendedData,
        searching: false,
      };

    case "SEARCH_ALL_CUSTOMERS_FAILURE":
      return {
        ...state,
        error: action.error,
        searching: false,
      };

    case "EDITCUSTOMER_REQUEST":
      return {
        ...state,
        updating: true,
      };

    case "EDITCUSTOMER_SUCCESS":
      return {
        ...state,
        updating: false,
      };

    case "EDITCUSTOMER_FAILURE":
      return {
        ...state,
        error: action.error,
        updating: false,
      };

    case "DELETECUSTOMER_REQUEST":
      return {
        ...state,
        deleting: true,
      };

    case "DELETECUSTOMER_SUCCESS":
      return {
        ...state,
        deleting: false,
      };

    case "DELETECUSTOMER_FAILURE":
      return {
        ...state,
        error: action.error,
        deleting: false,
      };

    case "GETROLES_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GETROLES_SUCCESS":
      return {
        ...state,
        allRoles: action.allRoles,
        loading: false,
      };

    case "GETROLES_FAILURE":
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case "ASSIGNROLES_REQUEST":
      return {
        ...state,
        assigning: true,
      };

    case "ASSIGNROLES_SUCCESS":
      return {
        ...state,
        assigning: false,
      };

    case "ASSIGNROLES_FAILURE":
      return {
        ...state,
        assigning: false,
        error: action.error,
      };

    case "GETALLABILITY_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GETALLABILITY_SUCCESS":
      return {
        ...state,
        allAbility: action.allAbility,
      };

    case "GETALLABILITY_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "ASSIGNABILITY_REQUEST":
      return {
        ...state,
        assigning: true,
      };
    case "ASSIGNABILITY_SUCCESS":
      return {
        ...state,
        assigning: false,
      };

    case "ASSIGNABILITY_FAILURE":
      return {
        ...state,
        assigning: false,
        error: action.error,
      };

    case "GETCOMPANYSTATS_REQUEST":
      return {
        ...state,
        loadingCompStats: true,
      };

    case "GETCOMPANYSTATS_SUCCESS":
      return {
        ...state,
        companyStats: action.companyStats,
        loadingCompStats: false,
      };

    case "GETCOMPANYSTATS_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETCOURIERSTATS_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GETCOURIERSTATS_SUCCESS":
      return {
        ...state,
        courierStats: action.courierStats,
      };

    case "GETCOURIERSTATS_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETEMPLOYEESTATS_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GETEMPLOYEESTATS_SUCCESS":
      return {
        ...state,
        employeeStats: action.employeeStats,
      };

    case "GETEMPLOYEESTATS_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETGENERALSTATS_REQUEST":
      return {
        ...state,
        loadingGeneralStats: true,
      };

    case "GETGENERALSTATS_SUCCESS":
      return {
        ...state,
        generalStats: action.generalStats,
      };

    case "GETGENERALSTATS_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "CREATEORDER_REQUEST":
      return {
        ...state,
        creating: true,
      };

    case "CREATEORDER_SUCCESS":
      return {
        ...state,
        creating: false,
      };

    case "CREATEORDER_FAILURE":
      return {
        ...state,
        error: action.error,
        creating: false,
      };

    case "CREATERECORD_REQUEST":
      return {
        ...state,
        recording: true,
      };
    case "CREATERECORD_SUCCESS":
      return { ...state, recording: false };
    case "CREATERECORD_FAILURE":
      return { ...state, error: action.error, recording: false };
    case "EDITORDER_REQUEST":
      return {
        ...state,
        updating: true,
      };

    case "EDITORDER_SUCCESS":
      return {
        ...state,
        updating: false,
      };

    case "EDITORDER_FAILURE":
      return {
        ...state,
        error: action.error,
        updating: false,
      };

    case "DELETEORDER_REQUEST":
      return {
        ...state,
        deleting: true,
      };

    case "DELETEORDER_SUCCESS":
      return {
        ...state,
        deleting: false,
      };

    case "DELETEORDER_FAILURE":
      return {
        ...state,
        error: action.error,
        deleting: false,
      };

    case "GETCOUNTRIES_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GETCOUNTRIES_SUCCESS":
      return {
        ...state,
        countries: action.countries,
      };

    case "GETCOUNTRIES_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETALLSTATES_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GETALLSTATES_SUCCESS":
      return {
        ...state,
        states: action.states,
      };

    case "GETALLSTATES_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETSTATES_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GETSTATES_SUCCESS":
      return {
        ...state,
        allStates: action.allStates,
      };

    case "GETSTATES_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETCITIES_SUCCESS":
      return {
        ...state,
        city: action.city,
      };

    case "GETALLOUTLET_REQUEST":
      return {
        ...state,
        getOutletLoader: action.payload,
      };

    case "GETALLOUTLET_SUCCESS":
      return {
        ...state,
        allOutlet: action.allOutlet,
      };

    case "GETALLOUTLET_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "CREATEOUTLET_REQUEST":
      return {
        ...state,
        creating: true,
      };

    case "CREATEOUTLET_SUCCESS":
      return {
        ...state,
        creating: false,
      };

    case "CREATEOUTLET_FAILURE":
      return {
        ...state,
        error: action.error,
        creating: false,
      };

    case "SEARCH_ALL_OUTLET_REQUEST":
      return {
        ...state,
        searching: true,
      };

    case "SEARCH_ALL_OUTLET_SUCCESS":
      let addedData = {
        ...state.allOutlet,
        data: {
          ...state.allOutlet?.data,
          data: [...action.searchedOutlet?.data],
        },
      };

      return {
        ...state,
        allOutlet: addedData,
        searching: false,
      };

    case "SEARCH_ALL_OUTLET_FAILURE":
      return {
        ...state,
        error: action.error,
        searching: false,
      };

    case "SEARCHCOMPANIES_REQUEST":
      return {
        ...state,
        searching: true,
      };

    case "SEARCHCOMPANIES_SUCCESS":
      let searchedData = {
        ...state.companies,
        data: {
          ...state.companies?.data,
          data: [...action.searchedCompany?.data],
        },
      };
      return {
        ...state,
        companies: searchedData,
        searching: false,
      };
    case "SEARCHCOMPANIESFAILURE":
      return {
        ...state,
        error: action.error,
        searching: false,
      };
    case "SEARCHEMPLOYEE_REQUEST":
      return {
        ...state,
        searching: true,
      };

    case "SEARCHEMPLOYEE_SUCCESS":
      let theAppendedData = {
        ...state.allEmployees,
        data: {
          ...state.allEmployees?.data,
          data: [...action.searchedEmployee?.data],
        },
      };

      return {
        ...state,
        allEmployees: theAppendedData,
        searching: false,
      };

    case "SEARCHEMPLOYEE_FAILURE":
      return {
        ...state,
        error: action.error,
        searching: false,
      };

    case "EDITOUTLET_REQUEST":
      return {
        ...state,
        updating: true,
      };

    case "EDITOUTLET_SUCCESS":
      return {
        ...state,
        updating: false,
      };

    case "EDITOUTLET_FAILURE":
      return {
        ...state,
        error: action.error,
        updating: false,
      };

    case "DELETEOUTLET_REQUEST":
      return {
        ...state,
        deleting: true,
      };

    case "DELETEOUTLET_SUCCESS":
      return {
        ...state,
        deleting: false,
      };

    case "DELETEOUTLET_FAILURE":
      return {
        ...state,
        error: action.error,
        deleting: false,
      };

    case "GETMYCOMPANY_REQUEST":
      return {
        ...state,
        getCompanyLoader: action.payload,
      };

    case "GETMYCOMPANY_SUCCESS":
      return {
        ...state,
        myCompany: action.myCompany,
      };

    case "GETMYCOMPANY_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETACOMPANY_REQUEST":
      return {
        ...state,
        getCompanyLoader: action.payload,
      };

    case "GETACOMPANY_SUCCESS":
      return {
        ...state,
        singleCompany: action.singleCompany,
      };

    case "GETACOMPANY_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "GETMYCOURIER_REQUEST":
      return {
        ...state,
        getCourierLoader: action.payload,
      };

    case "GETMYCOURIER_SUCCESS":
      return {
        ...state,
        myCourier: action.myCourier,
      };

    case "GETMYCOURIER_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "CREATETHIRDPARTYTOKEN_REQUEST":
      return {
        ...state,
        creating: true,
      };

    case "CREATETHIRDPARTYTOKEN_SUCCESS":
      return {
        ...state,
        // allEmployees: action.allEmployees,
        creating: false,
      };

    case "CREATETHIRDPARTYTOKEN_FAILURE":
      return {
        ...state,
        error: action.error,
        creating: false,
      };

    case "GETTHIRDPARTYTOKEN_REQUEST":
      return {
        ...state,
        getSettingsLoader: action.payload,
      };

    case "GETTHIRDPARTYTOKEN_SUCCESS":
      return {
        ...state,
        thirdPartyToken: action.thirdPartyToken,
      };

    case "GETTHIRDPARTYTOKEN_FAILURE":
      return {
        ...state,
        error: action.error,
      };
    case "GETLOCATION_LIST":
      return {
        ...state,
        locationList: action.locationList,
      };
    default:
      return state;
  }
}
