import React, { useState, useEffect } from "react";
import AuthLayout from "../layouts/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { userActions, adminActions } from "../../redux/actions";
import { Beat } from "../../redux/reducers/spinner";
import Location from "../includes/Location";
import PhoneInput from "react-phone-number-input";

export default function RegisterCompany() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminActions.getCountries());
  }, [dispatch]);

  const registering = useSelector((state) => state.registration.registering);

  // Used to toggle the password visibility
  // function toggleFunction(e) {
  //   var x = document.getElementById("password");
  //   if (x.type === "password") {
  //     x.type = "text";
  //   } else {
  //     x.type = "password";
  //   }

  //   // toggle the eye slash icon
  //   e.target.classList.toggle("i-Eye1");
  // }
  // function toggleFunction2(e) {
  //   var x = document.getElementById("password2");
  //   if (x.type === "password") {
  //     x.type = "text";
  //   } else {
  //     x.type = "password";
  //   }

  //   // toggle the eye slash icon
  //   e.target.classList.toggle("i-Eye1");
  // }

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  // Form actions
  const [submitted, setSubmitted] = useState(false);
  let [company_phone, setCompanyPhone] = useState("");
  const [data, setData] = useState({
    // user_name: "",
    // user_email: "",
    // user_password: "",
    // user_password_confirmation: "",

    company_name: "",
    company_email: "",
    company_phone: "",
    company_address: "",
    company_city: "",
    company_state: "",
    company_country: "",
  });
  const {
    // user_name,
    // user_email,
    // user_password,
    // user_password_confirmation,

    company_name,
    company_email,
    company_address,
    company_city,
    company_state,
    company_country,
  } = data;
  company_phone = company_phone?.substring(1);

  function handleChange(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  }

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    if (
      // user_email &&
      company_name &&
      company_email &&
      company_phone &&
      company_address &&
      company_city &&
      company_state &&
      company_country
    ) {
      const newData = { ...data, company_phone: company_phone };
      // get return url from location state or default to home page
      dispatch(userActions.registerCompany(newData));
    }
  }

  return (
    <AuthLayout>
      <div className="auth-box">
        <div className="auth-title">
          <h1 className="mb-4 text-primary font-weight-bold">
            Get started with Ogwugo Delivery
          </h1>
          <h4 className="mb-4">
            Create your company by entering the information below{" "}
          </h4>
        </div>
        <form onSubmit={handleSubmit} autoComplete="off">
          {/* <h3>Enter user details</h3> */}
          {/* <div className="text-left row pt-4">
            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && user_name ? " has-error" : "")
              }
            >
              <label htmlFor="user_name">User name*</label>
              <input
                type="text"
                className="form-control"
                name="user_name"
                onChange={handleChange}
                autoComplete="off"
                required
              />
              {submitted && !user_name && (
                <div className="help-block">User name is required</div>
              )}
            </div>

            <div
              className={
                "form-group col-md-6 mb-4" +
                (submitted && user_email ? " has-error" : "")
              }
            >
              <label htmlFor="user_email">User email*</label>
              <input
                type="email"
                className="form-control"
                name="user_email"
                onChange={handleChange}
                autoComplete="off"
                required
              />
              {submitted && !user_email && (
                <div className="help-block">User email is required</div>
              )}
            </div>
            <div
              className={
                "form-group col-md-6 mb-4" +
                (submitted && user_phone ? " has-error" : "")
              }
            >
              <label htmlFor="user_phone">User phone*</label>
              <PhoneInput
                defaultCountry="NG"
                className="form-control"
                value={user_phone}
                onChange={setUserPhone}
              />
              {submitted && !user_phone && (
                <div className="help-block">User phone is required</div>
              )}
            </div>
            <div
              className={
                "form-group col-md-6 mb-4" +
                (submitted && user_password ? " has-error" : "")
              }
            >
              <label htmlFor="user_password">User password*</label>
              <input
                type="password"
                id="password"
                className="form-control"
                name="user_password"
                onChange={handleChange}
              />
              {submitted && !user_password && (
                <div className="help-block">User password is required</div>
              )}
              <i
                className="i-Eye-Visible toggleIcon"
                onClick={toggleFunction}
              ></i>
            </div>
            <div
              className={
                "form-group col-md-6 mb-4" +
                (submitted && user_password_confirmation ? " has-error" : "")
              }
            >
              <label htmlFor="user_password_confirmation">
                User password confirmation*
              </label>
              <input
                type="password"
                id="password2"
                className="form-control"
                name="user_password_confirmation"
                onChange={handleChange}
              />
              {submitted && !user_password_confirmation && (
                <div className="help-block">
                  User password confirmation is required
                </div>
              )}
              <i
                className="i-Eye-Visible toggleIcon"
                onClick={toggleFunction2}
              ></i>
            </div>
          </div> */}

          {/* <h3 className="mt-4">Enter Company details</h3> */}
          <div className="text-left row pt-4">
            <div
              className={
                "form-group col-md-6 mb-4" +
                (submitted && company_name ? " has-error" : "")
              }
            >
              <label htmlFor="company_name">Company name*</label>
              <input
                type="text"
                className="form-control"
                name="company_name"
                onChange={handleChange}
                autoComplete="off"
                required
              />
              {submitted && !company_name && (
                <div className="help-block">Company name is required</div>
              )}
            </div>
            <div
              className={
                "form-group col-md-6 mb-4" +
                (submitted && company_email ? " has-error" : "")
              }
            >
              <label htmlFor="company_email">Company email*</label>
              <input
                type="email"
                className="form-control"
                name="company_email"
                onChange={handleChange}
                autoComplete="off"
                required
              />
              {submitted && !company_email && (
                <div className="help-block">Company email is required</div>
              )}
            </div>

            <div
              className={
                "form-group col-md-12 mb-4" +
                (submitted && company_address ? " has-error" : "")
              }
            >
              <label htmlFor="company_address">Company address*</label>
              <input
                type="text"
                className="form-control"
                name="company_address"
                onChange={handleChange}
                required
              />
              {submitted && !company_address && (
                <div className="help-block">Company address is required.</div>
              )}
            </div>

            <div
              className={
                "form-group col-md-6 mb-4" +
                (submitted && company_phone ? " has-error" : "")
              }
            >
              <label htmlFor="company_phone">Company phone*</label>
              <PhoneInput
                defaultCountry="NG"
                className="form-control"
                value={company_phone}
                onChange={setCompanyPhone}
              />
              {submitted && !company_phone && (
                <div className="help-block">Company phone is required</div>
              )}
            </div>

            {/* Here, we utilize the location component to get the location data */}
            <Location
              country_id={locationId.country_id}
              state_id={locationId.state_id}
              city_id={locationId.city_id}
              countryList={(value) => {
                setLocationList({ ...locationList, countryList: value });
              }}
              stateList={(value) => {
                setLocationList({ ...locationList, stateList: value });
              }}
              cityList={(value) => {
                setLocationList({ ...locationList, cityList: value });
              }}
            />
            <div
              className={
                "form-group col-md-6 mb-4" +
                (submitted && company_country ? " has-error" : "")
              }
            >
              <label htmlFor="company_country">Company country*</label>

              <select
                name="company_country"
                className="form-control"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    country_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
                required
              >
                <option>Select option</option>
                {locationList.countryList &&
                  locationList.countryList.length > 0 &&
                  locationList.countryList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !company_country && (
                <div className="help-block">Company country is required</div>
              )}
            </div>
            <div
              className={
                "form-group col-md-6 mb-4" +
                (submitted && company_state ? " has-error" : "")
              }
            >
              <label htmlFor="company_state">Company state*</label>

              <select
                name="company_state"
                className="form-control"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    state_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
                required
              >
                <option>Select option</option>
                {locationList.stateList &&
                  locationList.stateList.length > 0 &&
                  locationList.stateList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !company_state && (
                <div className="help-block">Company state is required.</div>
              )}
            </div>
            <div
              className={
                "form-group col-md-6 mb-4" +
                (submitted && company_city ? " has-error" : "")
              }
            >
              <label htmlFor="company_city">Company city*</label>

              <select
                name="company_city"
                className="form-control"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    city_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
                required
              >
                <option>Select option</option>
                {locationList.cityList &&
                  locationList.cityList.length > 0 &&
                  locationList.cityList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !company_city && (
                <div className="help-block">Company city is required</div>
              )}
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-rounded btn-primary btn-block mt-4"
          >
            {registering === true ? (
              <Beat loading={registering} />
            ) : (
              "Register Company"
            )}
          </button>
        </form>
        {/* <div className="mt-3 mb-4 text-center">
          <a className="text-muted" href="/auth/login">
            {" "}
            <u>Login</u>
          </a>
        </div> */}
      </div>
    </AuthLayout>
  );
}
